import axios from "axios";
import { CookieStorage } from 'cookie-storage'
import router from '@/plugins/router'
const BASE_API_URL = process.env.VUE_APP_API_URL

export const handleRequest = async (path, params = null, method = 'GET', data) => {
  const cookieStorage = new CookieStorage()
  const token = cookieStorage.getItem('access_token')
  try {
    return await axios({
      method,
      url: BASE_API_URL + path,
      data,
      params,
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
  } catch (error) {
    if (error.response?.status === 401) {
      await router.push('/login')
    }
    console.error(error)
    throw error
  }
}

export const handleRequestUpload = async (path, data) => {
  const cookieStorage = new CookieStorage()
  const token = cookieStorage.getItem('access_token')
  const formData = new FormData()
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });
  try {
    return await axios.post(BASE_API_URL + path, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'content-type': 'multipart/form-data'
      }
    })
  } catch (error) {
    if (error.response?.status === 401) {
      await router.push('/login')
    }
    console.error(error)
    throw error
  }
}

export const apiManagement = {
  getAllSkuOptions: async () => await handleRequest('/productVariants/all-options'),
  getListStockIn: async (params) => await handleRequest('/stockIns', params),
  getListStockOut: async (params) => await handleRequest('/stock-out/list-skus', params),
  getListStockOutHistory: async (params) => await handleRequest('/stock-out', params),
  getListHistory: async (params) => await handleRequest('/stockIns/history', params),
  getStockOutHistory: async (id) => await handleRequest(`/stock-out/${id}`, null, 'GET'),
  getTotalValueOut: async (params) => await handleRequest('/stock-out/total-value', params),
  createStockIn: async (data) => await handleRequest('/stockIns/', null, 'POST', data),
  updateStockIn: async (id, data) => await handleRequest(`/stockIns/${id}`, null, 'PUT', data),
  deleteStockIn: async (data) => await handleRequest(`/stockIns/@/bulkDelete`, null, 'POST', data),
  importStockIn:  async (data) => await handleRequestUpload('/stockIns/@/import', data),
  getListStockAvailable: async (params) => await handleRequest('/stock-available', params),
  updateStockInventory: async (id, data) => await handleRequest(`/inventory/${id}`, null, 'PUT', data),
  updateStockOut: async (id, data) => await handleRequest(`/stock-out/${id}`, null, 'PATCH', data),
  createStockOut: async (data) => await handleRequest(`/stock-out`, null, 'POST', data),
}
