
















































































import BaseDatePickerSelect from '@/components/BaseDatePickerSelect.vue';
import EditStockInfo from '@/components/inventory/modals/EditStockInfo.vue';
import { apiManagement } from '@/services/apis/apiManagement';
import coreApiClient from '@/services/apis/coreApiClient';
import { convertToTimestamp, formatDateFromBE, formatNumber, formatPrice } from '@/utils/format';
import { debounce } from 'lodash';

export default {
  components: {BaseDatePickerSelect, EditStockInfo},
  data() {
    return {
      variantFilterIds: [],
      allSkuOptions: [],
      listData: {
        data: [],
        count: 0,
      },
      totalData: {
        totalValue: 0
      },
      queryData: {
        offset: 0,
        limit: 50,
        orderBy: 'createdTime',
        orderType: 'DESC',
        search: '',
        'filters[inventoryTime][start]': null,
        'filters[inventoryTime][end]': null,
      },
      currentPage: 1,
      rangeStockTime: {
        start: null,
        end: null
      },
      headers: [
        {
          text: 'SKU',
          sortable: false,
          value: 'sku',
        },
        {text: 'Unit', value: 'unit', sortable: false},
        {text: 'CURRENT Q.TY ', value: 'currentQuantity', align: 'end'},
        {text: 'CURRENT PRICE', value: 'currentPrice', align: 'end'},
        {text: 'TOTAL CURRENT VALUE', value: 'totalCurrentValue', sortable: true, align: 'end'},
        {text: 'REQUIRED Q.TY', value: 'requiredQuantity', align: 'end'},
        {text: 'REQUIRED STOCK IN', value: 'requiredStockin', align: 'end'},
        {text: 'Create time', value: 'createdTime'},
        {text: 'Action', value: 'actions', sortable: false},
      ],
      itemEdit: null,
      actionShowModalEdit: 0
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    formatNumber,
    formatDateFromBE,
    formatPrice,
    async initialize() {
      await Promise.all([
        this.getListSkuOption(),
        this.debouncedFetchData()
      ]);
    },

    debouncedFetchData: debounce(function () {
      this.getList();
    }, 300),

    getParamsList() {
      this.queryData['filters[inventoryTime][start]'] = this.rangeStockTime.start ? convertToTimestamp(this.rangeStockTime.start, 'DD/MM/YYYY') : null;
      this.queryData['filters[inventoryTime][end]'] = this.rangeStockTime.end ? convertToTimestamp(this.rangeStockTime.end, 'DD/MM/YYYY') : null;

      const params = {...this.queryData};
      this.variantFilterIds.forEach((id, index) => {
        params[`filters[productVariantId][${index}]`] = id;
      });

      return params;
    },

    async getList() {
      try {
        const response = await apiManagement.getListStockAvailable(this.getParamsList());
        this.listData = {
          data: response.data.data,
          count: response.data.count
        };
        this.totalData.totalValue = response.data.totalValue;
      } catch (e) {
        console.log(e);
      }
    },

    async getListSkuOption() {
      try {
        const response = await apiManagement.getAllSkuOptions();
        this.allSkuOptions = response.data;
      } catch (e) {
        console.log(e);
      }
    },

    changePage(value) {
      this.currentPage = value;
      this.queryData.offset = this.queryData.limit * (value - 1);
      this.debouncedFetchData();
    },

    changePerPage(value) {
      this.queryData.limit = value;
      this.debouncedFetchData();
    },

    onSortByChange(value) {
      this.queryData.orderBy = value || 'createdTime';
      this.debouncedFetchData();
    },

    onSortDescChange(value) {
      this.queryData.orderType = value === false ? 'ASC' : 'DESC';
      this.debouncedFetchData();
    },

    changeStockTimeFilter(value) {
      this.rangeStockTime = {...value};
      this.debouncedFetchData();
    },

    editItem(item) {
      this.itemEdit = item;
      this.actionShowModalEdit++;
    },

    handleExport() {
      coreApiClient.download(
          'stock-available',
          `@/export`,
          this.getParamsList()
      );
    }
  }
};
