import moment from 'moment';

const formatDateFromBE = (timestamp: number) => {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${day}/${month}/${year}`;
};

const formatDateTimeFromBE = (timestamp: number) => {
  const date = new Date(timestamp);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() trả về giá trị từ 0-11, nên cần +1
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};

const convertToTimestamp = (timeStr: string, formatStr: string): number => {
  const date = timeStr ? moment.parseZone(timeStr, formatStr) : moment();
  return date.valueOf();
};

const getTimeFromTimestamp = (timestamp: string, formatStr: string) => {
  return moment(timestamp).format(formatStr);
};

const formatPrice = (price: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(price);
};

const formatNumber = (value: string | number) => {
  const numberValue = typeof value === 'string' ? parseFloat(value) : value;
  if (isNaN(numberValue)) {
    console.error('Invalid number input');
    return value;
  }
  return numberValue.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 20
  });
};

export {
  formatPrice,
  formatNumber,
  formatDateFromBE,
  formatDateTimeFromBE,
  convertToTimestamp,
  getTimeFromTimestamp
};
