var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout-page-content"},[_c('div',{staticClass:"page-content-container"},[_c('div',{staticClass:"d-flex flex-wrap justify-center justify-md-space-between mb-2"},[_vm._m(0),_c('div',{staticClass:"col-3 py-2"}),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('v-btn',{staticClass:"text-button ml-3",attrs:{"color":"default"},on:{"click":_vm.handleExport}},[_vm._v("Export")])],1)]),_c('div',{staticClass:"general-information d-flex flex-wrap justify-space-between"},[_c('div',{staticClass:"total-information d-flex align-center"},[_c('div',{staticClass:"mr-3"},[_vm._v("Total Value "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.formatPrice(_vm.totalData.totalValue)))])])]),_c('div',{staticClass:"filter-time-sku-area"},[_c('v-autocomplete',{staticClass:"filterSku-area",attrs:{"items":_vm.allSkuOptions,"item-text":"sku","item-value":"_id","label":"Filter by SKU","multiple":""},on:{"change":_vm.getList},model:{value:(_vm.variantFilterIds),callback:function ($$v) {_vm.variantFilterIds=$$v},expression:"variantFilterIds"}}),_c('BaseDatePickerSelect',{on:{"change":_vm.changeStockTimeFilter}})],1)]),_c('div',{staticClass:"mt-3"},[_c('v-data-table',{staticClass:"elevation-1 custom-data-table",attrs:{"headers":_vm.headers,"footer-props":{'items-per-page-options':[10, 20, 50, 100, 200, 500]},"items-per-page":_vm.queryData.limit,"items":_vm.listData.data,"server-items-length":_vm.listData.count,"sort-by":"createdTime"},on:{"update:sort-by":_vm.onSortByChange,"update:sort-desc":_vm.onSortDescChange,"update:page":_vm.changePage,"update:items-per-page":_vm.changePerPage},scopedSlots:_vm._u([{key:"item.currentPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPrice(item.currentPrice))+" ")]}},{key:"item.totalCurrentValue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPrice(item.totalCurrentValue))+" ")]}},{key:"item.createdTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateFromBE(item.createdTime))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])]}}])})],1)]),_c('EditStockInfo',{attrs:{"action-show-modal":_vm.actionShowModalEdit,"item-edit":_vm.itemEdit},on:{"success":_vm.debouncedFetchData}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column justify-center"},[_c('h4',{staticClass:"text-h5"},[_vm._v("Stock Information")])])}]

export { render, staticRenderFns }