<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card class="dialog-form">
        <v-card-title>Edit Stock Information</v-card-title>
        <v-btn class="close-button" @click="dialog = false">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                  fill="#1D1B20" />
          </svg>
        </v-btn>
        <form>
          <v-text-field
              v-model="quantity"
              :error-messages="quantityErrors"
              label="Required Quantity"
              type="number"
              required
              @input="$v.quantity.$touch()"
              @blur="$v.quantity.$touch()"
          ></v-text-field>
          <v-textarea
              v-model="description"
              label="Description (Optional)"
              @input="$v.description.$touch()"
              @blur="$v.description.$touch()"
          ></v-textarea>
          <v-card-actions class="flex">
            <v-btn class="mr-4" @click="dialog = false">
              Cancel
            </v-btn>
            <v-btn color="primary" @click="submit">
              Complete
            </v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { apiManagement } from '@/services/apis/apiManagement'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'EditStockInfo',
  mixins: [validationMixin],
  validations: {
    quantity: { required }
  },

  data() {
    return {
      dialog: false,
      description: '',
      quantity: null,
    }
  },

  props: {
    actionShowModal: {
      type: Number
    },
    itemEdit: {
      type: Object
    }
  },

  watch: {
    actionShowModal(newVal) {
      if (newVal) {
        this.dialog = true
        this.description = this.itemEdit.description
        this.quantity = this.itemEdit.requiredQuantity
      }
    }
  },

  computed: {
    quantityErrors() {
      const errors = []
      if (!this.$v.quantity.$dirty) {
        return errors
      }
      !this.$v.quantity.required && errors.push('Quantity In is required')
      return errors
    }
  },

  methods: {
    async submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      try {
        await apiManagement.updateStockInventory(this.itemEdit.inventoryId, {
          data: {
            requiredQty: this.quantity,
            description: this.description
          }
        })

        this.$store.commit('setSuccessMessage', 'Save stock information successful')
        this.dialog = false
        this.$emit('success')
      } catch (e) {
        this.$store.commit('setErrorMessage', 'Save stock information has error')
        console.log(e)
      }
    },
    clear() {
      this.$v.$reset()
      this.description = ''
      this.quantity = null
    },
  },
}

</script>

<style lang="scss" scoped>
.dialog-form {
  padding: 34px 31px;
}

.v-card__title {
  padding: 0 !important;
  margin-bottom: 32px;
}

.close-button {
  position: absolute;
  top: 26px;
  right: 32px;
  background-color: transparent !important;
  border: none;
  box-shadow: none;
  padding: 0 !important;
  height: unset !important;
  min-width: unset !important;
  border-radius: 0;
}

::v-deep .v-input__prepend-outer {
  display: none !important;
}

.v-card__actions {
  justify-content: flex-end;
  padding-bottom: 0;
}
</style>
